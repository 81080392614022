import * as React from "react"
import SiteContainer from "../components/site-container";
import {Helmet} from "react-helmet";
import firstparty_attribution_dashboard
    from "../images/pages/solutions/attribution/firstparty-attribution-dashboard-full.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PricingSection from "../components/PricingSection";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Attribution & Analytics</title>
                <meta name="description" content="Firstparty is a programmable marketing analytics and attribution tool for marketers & developers."></meta>
                
            </Helmet>

            <div className="section py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 mb-7 text-center">
                            <h1 className="display-3 fw-bold">
                                Attribution & Analytics You Can Trust
                            </h1>
                            <p className="lead text-muted mb-6">
                                Data-driven marketing attribution, fed by your existing warehouse or our <strong>free</strong> marketing analytics platform.
                            </p>
                            <div className="d-grid gap-2 d-md-block mb-8 mb-md-0">
                                <a className="btn btn-primary mx-md-2" href="/signup/">
                                    Get Started for Free
                                </a>
                                <a className="btn btn-outline-primary" href="/solutions/attribution/">
                                    Learn More<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <img className="screenshot img-fluid" src={firstparty_attribution_dashboard} />
                        </div>
                    </div>

                </div>
            </div>

            <section className="py-8 py-md-10">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6}>
                            <div className="card bg-light">
                                <div className="card-body">
                                    <span className="badge rounded-pill bg-secondary badge-float badge-float-outside">Free in Beta</span>
                                    <h3>
                                        Data-driven Marketing Attribution
                                    </h3>
                                    <p className="text-gray-700">
                                        Firstparty generates multi-touch, data-driven
                                        attribution reports without human bias.
                                    </p>
                                    <a className="btn btn-primary btn-sm" href="/solutions/attribution/">
                                        More on Attribution<i className="fe fe-arrow-right ms-2" />
                                    </a>

                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="card bg-light">
                                <div className="card-body">
                                    <span className="badge rounded-pill bg-primary badge-float badge-float-outside">Free Forever</span>
                                    <h3>
                                        Free Marketing Analytics
                                    </h3>
                                    <p className="text-gray-700">
                                        Collect all customer interactions and build a complete report of the customer
                                        journey, no matter the source.
                                    </p>
                                    <a className="btn btn-outline-secondary btn-sm" href="/solutions/analytics/">
                                        More on Analytics<i className="fe fe-arrow-right ms-2" />
                                    </a>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 pt-md-11 pb-md-12 bg-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="fw-bold text-white">
                                All-in-One Analytics & Attribution
                            </h2>
                            <p className="fs-lg text-gray-200 mb-7 mb-md-9">
                                Firstparty is designed to make data collection and reporting fast and easy.
                            </p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Website Javascript SDK
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Full API Access
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    First-party Cookies
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Warehouse Import/Export
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Customizable Reports
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Filter & Refine
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Unlimited Users
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white mb-0">
                                    Custom Events & Properties
                                </p>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Data-Driven Model
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Run in Minutes
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Simple Configuration
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Analyze Warehouse or Firstparty Data
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Persistent Identifiers
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white">
                                    Import from Salesforce
                                </p>
                            </div>
                            <div className="d-flex">
                                <div className="badge badge-rounded-circle bg-secondary mt-1 me-4">
                                    <i className="fe fe-check"></i>
                                </div>
                                <p className="text-white mb-6 mb-lg-0">
                                    Customer Journey Funnel Reports
                                </p>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <hr />
                            <a href="/solutions/analytics/" className="text-white fw-bold text-decoration-none">
                                Learn More about Analytics<i className="fe fe-arrow-right ms-3"></i>
                            </a>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <hr />
                            <a href="/solutions/attribution/" className="text-white fw-bold text-decoration-none">
                                Learn More about Attribution<i className="fe fe-arrow-right ms-3"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
